import { useState } from 'react';
import logo from '../assets/car-wash-logo.png';
import favicon from '../assets/car-wash-logo.png';
import Overlay from './Overlay';
import { className } from '../utils/utils';

interface NavLink {
    name:string,
}

interface HeaderPropsI {
    scrollToElement: (val: string) => void
}

const Header: React.FC<HeaderPropsI> = ({scrollToElement}) => {

    const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(false);

    const navLinks: NavLink[] = [
        {name:"contacts"},
        {name:"location"},
        {name:"services"},
    ]

    const closeSideMenu = ():void => setSideMenuOpen(false)
    const toggleSideMenu = () => setSideMenuOpen(prev => !prev)

    const handleMenuItemClick = (name:string) => {
        scrollToElement(name)
        setSideMenuOpen(false)
    }

    return (
        <div className="header-container">
            
            <div className="logo-container">
                <img src={logo} alt="logo" className="logo" />
            </div>

            <div className="nav-links">
                {navLinks.map(({name}) => (
                    <h4 onClick={() => scrollToElement(name)} key={name} className="nav-link">{name}</h4>
                ))}
            </div>

            <div onClick={toggleSideMenu} className={className("hamburger-btn", sideMenuOpen)}>
                <div className="slice top-slice"></div>
                <div className="slice mid-slice"></div>
                <div className="slice bot-slice"></div>
            </div>

            <div className={className("side-menu-container", sideMenuOpen)}>
                <div className="side-menu-colour-strip"></div>
                <img src={favicon} alt="favicon" className="side-menu-logo" />
                {navLinks.map(({name}) => (
                    <div key={name} onClick={()=>handleMenuItemClick(name)} className="side-menu-item">
                    <h4 className="side-menu-link">{name}</h4>
                    </div>
                ))}
            </div>

            <Overlay isOpen={sideMenuOpen} close={closeSideMenu} />
        </div>
    );
}

export default Header;
