import MainBtn from './../components/MainBtn';
import { formStatuses } from './../utils/variables';
// import illustration from '../assets/illustration.png';
import emailjs from "emailjs-com";
import loadingImg from '../assets/loading.gif'
import illustration from '../assets/ill.png';
import briefcase from '../assets/sponge.png';
import checkImg from '../assets/check.png';
import emailIcon from '../assets/navlink-icons/email.svg';
import SectionHeading from './../components/SectionHeading';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

emailjs.init('user_jw0mWcZ6PAjV4NtBWu2r9');

interface HomepagePropsI {
    scrollToElement: (val: string) => void
}

const Homepage: React.FC<HomepagePropsI> = ({ scrollToElement }) => {
    const { failed, loading, success, waiting } = formStatuses
    const [formStatus, setFormStatus] = useState(waiting)
    const { register, handleSubmit, formState: { errors } } = useForm();

    const cards = ["body wash & wax", "interior wash & vacuum", "tyre & rim shine"]

    interface FormData {
        name?:string,
        email:string,
        message: string
    }

    const onFormSubmit = (data: FormData) => {
        setFormStatus(loading)

        const {name="Unknown", message, email} = data
        const templateParams = {name: name + " (car-wash site)", message, email}

        emailjs.send("pp", "tkdevtemplateid", templateParams)
        .then(() => setFormStatus(success))
        .catch(() => setFormStatus(failed))
    }

    return (
        <div className="homepage-container">
            <div className="hero-container">
                <div className="hero-container-content">
                    <div className="hero-left">
                        <h1 className="hero-heading">Get you car cleaned from only R50.</h1>
                        <p className="hero-description">The only thing messy and worth loving moved from Barcelona to Paris. Visit us in Katlehong, bring your car in for a wash and we'll get it right and ready for the journey ahead.</p>
                        <div className="hero-btns">
                            <MainBtn text="Location" handler={() => scrollToElement("location")} />
                            <MainBtn text="Contacts" handler={() => scrollToElement("contacts")} />
                        </div>
                    </div>
                    <div className="hero-right">
                        <img src={illustration} alt="hero-illustration" className="hero-illustration" />
                    </div>
                </div>
            </div>

            <div className="mobile-illustration"></div>
            
            <div id="services" className="services-container">
            <SectionHeading text={"services"}/>
            <div className="services">
                <div className="service recruitment-services">
                    <div className="service-text-container">
                    <h1 className="service-heading">We Got Options!</h1>
                    <p className="service-description">We offer a complete range of services with full body wash and go, wash and dry and wash and wax options. Plus interior and ceiling washes to make the cockpit as fresh as home. As well as 3 Michelin star tyre and rim treatment.</p>
                    </div>                    
                    <img src={briefcase} alt="briefcase" className="service-image" />
                </div>
                
                <div className="service-cards-container">
                    {
                        cards.length && cards.map(cardText => (
                            <div key={cardText} className="service-card">
                                <img src={checkImg} alt="check mark" className="service-card-img" />
                                <p className="service-card-text">{cardText}</p>
                            </div>
                        ))
                    }
                </div>
                {/* <div className="service career-assistance">
                    <div className="service-text-container">
                    <h1 className="service-heading">Professional<br />Assistance</h1>
                    <p className="service-description">We offer a wide range of services that aid you in managing and/or enhancing your professional capacity.</p>
                    <p className="service-description">
                    <span>{"•"}Payroll services</span>
                    <span>{" •"}Career counselling</span>
                    <span>{" •"}Company health and wellness</span>
                    <span>{" •"}Contracting Marketing services</span>
                    <span>{" •"}Contracting Financial service</span>
                    <span>{" •"}Labour/CCMA Consulting</span>
                    <span>{" •"}Soft skills training/Creating manuals</span>
                    </p>
                    </div>
                    <img src={gradHat} alt="graduation-hat" className="service-image" />
                </div> */}
            </div>

            </div>
            
            <div id="location" className="team-container">
            <SectionHeading text="let's meet"/>
            <div className="team-bg"></div>
            <p className="team-description">Open Mon-Sat 08:00-18:00 <br /> Sun 09:00-14:00.</p>

            <div className="contacts-map-container">
                <div className="contacts-map">
                    <iframe
                    className="contacts-map-iframe"
                    frameBorder="0"
                    src={
                        "https://www.google.com/maps/embed/v1/place?q=-26.344410+28.162277&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                    }
                    ></iframe>
                </div>
            </div>

            </div>

            <div id="contacts" className="contacts-container">
            <SectionHeading text={"contact"}/>
            <p className="contacts-description">If you have queries, comments or suggestions, feel free to contact us through our form or the given email.</p>

            <div className="contacts-section-email">
                <img src={emailIcon} alt="email-envelope"  className="contact-email-icon" />
                <h1 className="contact-email">contact@k1autowash.co.za</h1>
            </div>

            {formStatus === failed && <h4 className="form-failed-message">
                Sorry, failed to send you message. Please try again.
              </h4>}

            { formStatus === success ? <div className="form-success-container">
              <img
                src={checkImg}
                alt="success green check"
                className="form-sent-tick"
              />
              <h3 className="contact-form-success-text">
                Thank you for reaching out. Your message has been sent.
              </h3>
            </div> 
            : <form onSubmit={handleSubmit(onFormSubmit)} className="contact-form">
                <input type="text" className="contact-form-input"
                placeholder="name"
                {...register("name")}
                />
                
                <input type="text" className="contact-form-input" placeholder="email address"
                {...register("email", {
                    required: {
                        value: true,
                        message: "email address required"
                    },
                    pattern:{
                        value: /^\S+@\S+\.\S+$/,
                        message:"enter a valid email address"
                    }
                 })}
                />
                {errors.email && <p className="input-error-text">{errors.email.message}</p>}

                <textarea
                className="form-textarea"
                placeholder="message"
                {...register("message", {
                    required: {
                        value: true,
                        message: "message required"
                    },
                 })}
                ></textarea>
                {errors.message && <p className="input-error-text">{errors.message.message}</p>}

                { formStatus === loading ? <img src={loadingImg} alt="form loading" className="form-loading-img" /> : <button type='submit' className="contact-form-submit-btn main-btn">send message</button>}
            </form>
            }
            </div>
            {/* <Vacancies homepage /> */}
        </div>
    );
}

export default Homepage;
