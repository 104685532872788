import React from 'react';

interface IProps{
    text:string,
    handler: () => void
}

const MainBtn: React.FC<IProps> = ({text, handler}) => {
    return (
        <button className="main-btn" onClick={handler}>{text}</button>
    );
}

export default MainBtn;
