import React from 'react';

interface IProps{
    isOpen:boolean;
    close(): void
}

const Overlay: React.FC<IProps> = ({isOpen, close}): JSX.Element | null => {

    if(!isOpen) return null

    return (
        <div onClick={close} className="overlay"></div>
    );
}

export default Overlay;
