export const className = (className:string, dependentVar:boolean): string => dependentVar ? `${className} ${className}-open` : className

const cannotScrollAnymore = (scrollDownwards: boolean) => (!scrollDownwards && window.scrollY === 0) || (scrollDownwards && window.scrollY + window.innerHeight >= document.body.scrollHeight - 10)


export const customScrollTo = (scrollToVal:number) => {
    let stopAnimationId : null | number = null;
    const scrollFactor = 50
    const scrollDownwards = window.scrollY < scrollToVal
    
    const scrollHandler = () => {
        // console.log("animating")
      if (scrollDownwards && window.scrollY < scrollToVal && !cannotScrollAnymore(scrollDownwards)) {
        stopAnimationId = window.requestAnimationFrame(scrollHandler);
        window.scrollBy(0, scrollFactor);
      } else if (!scrollDownwards && window.scrollY > scrollToVal && !cannotScrollAnymore(scrollDownwards)) {
        stopAnimationId = window.requestAnimationFrame(scrollHandler);
        window.scrollBy(0, -scrollFactor);
        // cancelAnimationFrame(stopAnimationId);
      } else {
        // 
      }
    };

    scrollHandler();
  };