export const formStatuses = {
    loading: "loading",
    waiting: "waiting",
    success: "success",
    failed: "failed",
}




