import Header from './components/Header';
import Homepage from './pages/Homepage';
import Footer from './components/Footer';
import { customScrollTo } from './utils/utils';

const App = () => {

  const scrollToElement = (elementId: string) => {
    const element = document.getElementById(elementId)
    if (element){
        customScrollTo(element.offsetTop - 50)
    }
}

  return (
    <>
    <div className="app-container">
      <Header scrollToElement={scrollToElement} />
      <Homepage scrollToElement={scrollToElement} />
    </div>
    <Footer />
    </>
  );
}

export default App;
