

const Footer = () => {

    const footerText: string = `K1 Auto Wash ${new Date().getFullYear()}`

    return (
        <div className="footer-container">
            {footerText}
        </div>
    );
}

export default Footer;
